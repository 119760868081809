<app-navbar-style-one></app-navbar-style-one>

<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <h3>Email has sent!</h3>
                <p>Thank you for contacting us. We will response back to you soon!</p>
                <a routerLink="/" class="default-btn1 page-btn">Return To Home Page</a>
            </div>
        </div>
    </div>
</div>