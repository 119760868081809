<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape1 bg-color2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Current Project</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Current Project</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <h2>Current Project</h2>
        </div>

        <div class="row" >
            <div class="col-lg-4 col-md-6" *ngFor="let p of currentProject">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <img src="assets/img/clients/{{p.image}}" alt="Course Images">
                        <div class="courses-card-text">{{p.value}}</div>
                    </div>
                    <ul>
                        <li class="active">{{p.commencement}} • {{p.period}}</li>
                    </ul>
                    <div class="courses-card-content">
                        <h3>Client: {{p.client}}</h3>
                        <span>Main Contractor: {{p.mainContract}}</span>
                        <p>{{p.projectTitle}}</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>