import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private httpClient: HttpClient) { }

  sendMail(receiver: string, subject: string, text: string, html: string) {
    let element = {
      receiver: receiver,
      subject: subject,
      text: text,
      html: html
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post<{result: any}>('https://us-central1-poscrm-rps-demo.cloudfunctions.net/appBinaindah/email', element)
      .subscribe(res => {
        if (res.result !== undefined) {
          let message = res.result;
         // console.log(message);

          resolve(message);
        } else {
          resolve(undefined);
        }
      })
    })
  }
}
