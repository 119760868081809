<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape3 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Corporate Structure</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Corporate Structure</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">         
            <div class="col-lg-6">
                <div class="instructor-content">
                    <h3>Suresh Kumar A/L Balakrishnan</h3>
                    <span>Director/CEO</span>
                    <ul class="instructor-social">
                        <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>                        
                    </ul>
                    <p> </p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="instructor-img">
                    <!-- <img src="assets/img/placeholder.png" alt="Images"> -->
                    <span>
                        <b>Responsibility:</b> Currently oversees the Groups Operations and is also involved in the planning, formulation and 
                                        implementation of the Groups Business Strategies.
                                        He is determined to bring the company to a greater height in-line with Company’s vision in becoming
                                        a reputable conglomerate and a market leader in construction.
                    </span>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-6">
                <div class="instructor-content">
                    <h3>Musa Bin Desa</h3>
                    <span>Director</span>
                    <ul class="instructor-social">
                        <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>                        
                    </ul>
                    <p> </p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="instructor-img">
                    <!-- <img src="assets/img/placeholder.png" alt="Images"> -->
                    <span>
                        <b>Responsibility:</b> He is a very experience person in the Construction Field and has been with the Company since 1st
                        established on 1st September 1994 known as Bina Makmur Keratong (M) Sdn. Bhd.
                        His strong ties and connection with Felda and JKR projects and Managing Projects in terms of resources
                        allocations and business expansion will add value to the company.
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="instructor-content">
                    <h3>Samsuddin Bin Yaacob</h3>
                    <span>Director</span>
                    <ul class="instructor-social">
                        <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>                        
                    </ul>
                    <p> </p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="instructor-img">
                    <!-- <img src="assets/img/placeholder.png" alt="Images"> -->
                    <span>
                        <b>Responsibility:</b> Vast experience in Managing Projects and familiar with government procedures and requirement.
                        responsible to develop Company, in seeking new job or projects and to develop a supply chain system
                        to ensure the objective and goals of company can be achieved.
                    </span>
                </div>
            </div>
        </div>
        
    </div>
</div>

<section class="trainers-area pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <h2>Senior Management</h2>
        </div>
 
        <div class="row mt-5">
            <div class="col-lg-3 col-sm-6 mb-5">
                <div class="trainer-card">
                    <!-- <img src="assets/img/placeholder.png" alt="Trainers Images"> -->

                    <div class="trainer-content">
                        <a routerLink="/corporate-structure"><h3>Ahmad Bakri Bin Abdul Rahim</h3></a>
                        <span>General Manager</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
                <div class="trainer-card">
                    <!-- <img src="assets/img/placeholder.png" alt="Trainers Images"> -->

                    <div class="trainer-content">
                        <a routerLink="/corporate-structure"><h3>Abdul Karim Bin Abdul Rahim</h3></a>
                        <span>Project Development Manager</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
                <div class="trainer-card">
                    <!-- <img src="assets/img/placeholder.png" alt="Trainers Images"> -->

                    <div class="trainer-content">
                        <a routerLink="/corporate-structure"><h3>Sahzale Bin Husain</h3></a>
                        <span>Project Engineer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
                <div class="trainer-card">
                    <!-- <img src="assets/img/placeholder.png" alt="Trainers Images"> -->

                    <div class="trainer-content">
                        <a routerLink="/corporate-structure"><h3>Saliman Bin Hj Salamon</h3></a>
                        <span>Construction Manager</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <!-- <img src="assets/img/placeholder.png" alt="Trainers Images"> -->

                    <div class="trainer-content">
                        <a routerLink="/corporate-structure"><h3>Muhamad Azim Bin Salleh</h3></a>
                        <span>Project Manager</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>