import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-project',
  templateUrl: './current-project.component.html',
  styleUrls: ['./current-project.component.scss']
})
export class CurrentProjectComponent implements OnInit {
  currentProject: any;

  constructor() { 
    this.currentProject = 
    [
      {
        image: 'jkr.png',
        projectTitle: 'Road construction project at Jalan Sebaya-Bukit Bujang Balai Badang Mensudut Lama, Segamat, Johor.',
        client: 'Jabatan Kerja Raya Malaysia',
        mainContract: 'Begitu Padu (M) Sdn Bhd',
        period: '24 Months',
        commencement: '23th October 2017',
        value: 'RM46,878,489.30'
      },
      {
        image: 'kpm.png',
        projectTitle: 'Propose construction and completion of Sekolah Menengah Sains Alor Gajah ( 25 Classroom) and other facilities related as above Lot 2124, Pekan Sg. Udang, Mukim Sg. Baru Ulu, Daerah Alor Gajah, Melaka',
        client: 'Kementerian Pendidikan Malaysia ',
        mainContract: 'Primudi Sdn Bhd',
        period: '24 Weeks',
        commencement: '06th October 2017',
        value: 'RM55,450,933.22'
      },
      {
        image: 'kpm.png',
        projectTitle: 'Construction of “Sekolah Berasrama Penuh Baharu” consist of 25 “Bilik Darjah, Asrama, Dewan Makan, Dewan Serbaguna” in Johor.',
        client: 'Kementerian Pendidikan Malaysia ',
        mainContract: 'Seri Jaya Perkasa Sdn Bhd',
        period: '124 Weeks',
        commencement: '06th October 2017',
        value: 'RM52,913,841.09'
      }
    ]
  }

  ngOnInit(): void {
  }

}
