import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { InstructorComponent } from './components/pages/instructor/instructor.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ServicesDetailsTwoComponent } from './components/pages/services-details-two/services-details-two.component';
import { ServicesDetailsThreeComponent } from './components/pages/services-details-three/services-details-three.component';
import { ServicesDetailsFourComponent } from './components/pages/services-details-four/services-details-four.component';
import { CurrentProjectComponent } from './components/pages/current-project/current-project.component';
import { CompletedProjectComponent } from './components/pages/completed-project/completed-project.component';
import { OrgChartComponent } from './components/pages/org-chart/org-chart.component';
import { EmailSentComponent } from './components/pages/email-sent/email-sent.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'about', component: AboutComponent},    
    {path: 'corporate-structure', component: InstructorComponent},    
    {path: 'career', component: PricingComponent},
    {path: 'achievements', component: TestimonialsComponent},    
    {path: 'error', component: ErrorComponent}, 
    {path: 'services', component: ServicesComponent},
    {path: 'facilities-operation-maintenance', component: ServicesDetailsComponent},
    {path: 'supply-construction-material', component: ServicesDetailsTwoComponent},
    {path: 'construction-civil-engineering', component: ServicesDetailsThreeComponent},
    {path: 'integrated-facilities-management', component: ServicesDetailsFourComponent},
    {path: 'current-project', component: CurrentProjectComponent},
    {path: 'completed-project', component: CompletedProjectComponent},
    {path: 'org-chart', component: OrgChartComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'email-sent', component: EmailSentComponent},
    {path: 'gallery', component: GalleryComponent},

    //{path: '', component: ErrorComponent},

    // {path: 'faq', component: FaqComponent},
    // {path: 'login', component: LoginComponent},
    // {path: 'register', component: RegisterComponent},
    // {path: 'terms-condition', component: TermsConditionComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    // {path: 'courses', component: CoursesComponent},
    // {path: 'appointment', component: AppointmentComponent},    
    // {path: 'news', component: NewsComponent},
    // {path: 'news-details', component: NewsDetailsComponent},
    // {path: 'shop', component: ShopComponent},
    // {path: 'cart', component: CartComponent},
    // {path: 'checkout', component: CheckoutComponent},
    // {path: 'products-details', component: ProductsDetailsComponent},    
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }