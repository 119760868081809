<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape1 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>About Us</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-detl pt-100 pb-70">
    <div class="container">
        <div class="common-dtel-img">
            <img src="assets/img/home-1/image-12.jpeg" alt="About Images">
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-text">
                    <h2>Binaindah Makmur (M) Sdn Bhd was incorporated 1st September 1994</h2>
                    <p>{{about1}}</p>
                    <p>{{about2}}</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="common-offer">
                    <div class="drive-img">
                        <img src="assets/img/home-1/image-1.jpeg" alt="Images">
                    </div>
                    
                    <div class="common-offer-text">
                        <h3>Collaborate With Us?</h3>
                        <a href="tel:+079444222" class="call-btn">Call Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <!-- <span>Our Products </span> -->
            <h2>Our Vision</h2>
            <p><strong>To be reputable and prestigious construction company with pression and excellence quality of work</strong></p>
        </div>

        <!-- <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/1.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Glass</h3></a>
                            <span>$35</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/2.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Rite</h3></a>
                            <span>$30</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/3.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Fins Bungee</h3></a>
                            <span>$75</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/4.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Life Jacket</h3></a>
                            <span>$25</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<section class="trainers-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <!-- <span>Our Trainers</span> -->
            <h2>Our Mission</h2>
            <!-- <p>
                1.	Committed to deliver a quality and service.
            </p>
            <p>
                2.	To be a responsible corporative.
            </p>
            <p>
                3.	To realize human resources potential.
            </p>
            <p>
                4.	To comply with statutory and legal requirement.
            </p> -->
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="service-item box-shadow">
                    <span routerLink="/service-details" class="service-item-icon"><i class="fa fa-tools"></i></span>
                    <span routerLink="/service-details" class="service-head"><h5>Committed to deliver a quality and service.</h5></span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="service-item box-shadow">
                    <span routerLink="/service-details" class="service-item-icon"><i class="fa fa-hands-helping"></i></span>
                    <span routerLink="/service-details" class="service-head"><h5>To be a responsible corporative.</h5></span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="service-item box-shadow">
                    <span routerLink="/service-details" class="service-item-icon"><i class="fa fa-network-wired"></i></span>
                    <span routerLink="/service-details" class="service-head"><h5>To realize human resources potential.</h5></span>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="service-item box-shadow">
                    <span routerLink="/service-details" class="service-item-icon"><i class="fa fa-gavel"></i></span>
                    <span routerLink="/service-details" class="service-head"><h5>To comply with statutory and legal requirement.</h5></span>
                </div>
            </div>            
        </div>

        <!-- <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/1.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>John Doe</h3></a>
                        <span>Professional Diver</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/2.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Evana Doe</h3></a>
                        <span>Professional Trainer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/3.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content active">
                        <a routerLink="/instructor"><h3>Smith Doe</h3></a>
                        <span>Professional Swimmer</span>
                        <div class="social-icon active">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/4.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Marida Doe</h3></a>
                        <span>Professional Instructor</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>