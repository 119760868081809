import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-completed-project',
  templateUrl: './completed-project.component.html',
  styleUrls: ['./completed-project.component.scss']
})
export class CompletedProjectComponent implements OnInit {
  completeProject: any;

  constructor() { 
    this.completeProject = 
    [
      {
        image: 'jkr-segamat.png',
        projectTitle: 'Propose to supply, construction & completion of a Semai Bakti (Type A) hall and other related work at SJK (C) Hwa Nan, Segamat, Johor. (Sistem IBS)',
        client: 'JKR Segamat',
        mainContract: '',
        period: '27.07.2016',
        commencement: '16.12.2015',
        value: 'RM830,893.72'
      },
      {
        image: 'jkr-segamat.png',
        projectTitle: 'Construction & completion of one (1) block and four (4) level Gentian building as well as other related work at SJK (C) Hwa Nan, Segamat, Johor. (Sistem IBS)',
        client: 'JKR Segamat',
        mainContract: '',
        period: '24.12.2014',
        commencement: '22.01.2014',
        value: 'RM5,126,195.25'
      },
      {
        image: 'jps.png',
        projectTitle: 'Drainage project at Bandar Batu Pahat. Construction and completion of main drainage at Parit Besar Pakej III Batu Pahat Johor.',
        client: 'Jabatan Pengairan Dan Saliran Negeri Johor',
        mainContract: '',
        period: '30.04.2013',
        commencement: '02.05.2012',
        value: 'RM4,934,915.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Completion of abandoned work & other work related at Sekolah Kebangsaan Seri Rejo Sari, Rengit, Batu Pahat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '17.05.2012',
        commencement: '16.01.2013',
        value: 'RM4,050,000.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Supply, construction and completion of a Semai Bakti (Type A) hall and other work related at Felda Keratong 01, Bandar Tun Razak, Pahang Darul Makmur Dan Rimbunan Emas Enterprise',
        client: 'Felda Engineering Services Sdn Bhd',
        mainContract: '',
        period: '25.09.2012',
        commencement: '15.02.2012',
        value: 'RM737,000.00'
      },
      {
        image: 'jps.png',
        projectTitle: 'Drainage project at Bandar Parit Sulong. Construction and completion of the main drainage at Parit Sulong Batu Bahat, Johor',
        client: 'JPS Negeri Johor',
        mainContract: '',
        period: '15.04.2012',
        commencement: '18.04.2011',
        value: 'RM4,633,940.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Road construction at Kampong Pasir Besar to Felda Jelai through Kampung Kurnia Jaya, Tampin, Negeri Sembilan',
        client: 'Metrovex Holdings Sdn Bhd',
        mainContract: '',
        period: '2011',
        commencement: '2010',
        value: 'RM2,542,339.50'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of one (1) block consist of four (4) level of labs building  & one (1) block four (4) level school building & other related work at “Sek. Men. Tinggi Segamat” Segamat, Johor ',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '04.11.2010',
        commencement: '05.05.2009',
        value: 'RM9,350,650.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of two (2) replacement block consist of four (4) level & other related work at “Sek. Keb. Gelang Cincin” Segamat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '12.07.2010',
        commencement: '05.05.2009',
        value: 'RM5,985,692.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion one (1) additional block of 3 level, one (1) dormitory block consist of three (3) level, two (2) units of warden house & other related work at “Sek. Men. Sains Johor” Kluang, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '11.01.2010',
        commencement: '10.02.2009',
        value: 'RM5,908,109.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion two (2) block of four (4) level for school building and other work related at “Sek. Keb. Temenggong Abdul Rahman” Segamat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '29.03.2009',
        commencement: '17.09.2007',
        value: 'RM5,457,750.00'
      },
      {
        image: 'jkr.png',
        projectTitle: 'Propose construction & completion of “Sekolah Rendah Di Kem Gemas” Tampin, Negeri Sembilan',
        client: 'JKR Malaysia',
        mainContract: '',
        period: '30.09.2007',
        commencement: '17.04.2006',
        value: 'RM10,701,547.15'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Rebuild construction works for disconnected route at Felda Tenggaroh, Mersing, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '15.03.2007',
        commencement: '16.01.2007',
        value: 'RM3,000,000.00'
      },
      {
        image: 'kplb.png',
        projectTitle: 'Construction & completion route from Kampung Kuala Paya to Kampung Balai Badang, Segamat, Johor through method “Design & Build”',
        client: 'Kementerian Pembangunan Luar Bandar',
        mainContract: '',
        period: '20.09.2006',
        commencement: '21.09.2004',
        value: 'RM28,000,000.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Propose construction & completion a “Pusat Kemasiapan Perabot” Melaka at Plot 1, 2, 13-17, agricultural area Bukit Rambai, Lot 1653, Mukim Tanjung Minyak, Daerah Melaka Tengah, Melaka',
        client: 'Malaysian Timber Industry Board',
        mainContract: '',
        period: '13.11.2004',
        commencement: '14.10.2003',
        value: 'RM5,575,862.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Upgrading project of route 1 from Gemas ke Ayer Hitam, Johor Darul Takzim The Execution and Completion Of Earthworks, Drainages, Pavement And Ground Treatment. Sub-Package 6 (Ch. 0 To Ch. 33.30)',
        client: 'Perembun (M) Sdn. Bhd.',
        mainContract: '',
        period: '27.05.2004',
        commencement: '27.09.2002',
        value: 'RM18,909,286.02'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of “Kompleks Rumah Guru Kelas ‘F’ “ (40 units) as much Five (5) block of 4 level buliding as well as other related job at Gemereh, Segamat, Johor Darul Takzim',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '24.06.2002',
        commencement: '24.06.2002',
        value: 'RM6,199,150.00'
      },
      {
        image: 'jata_kpm.png',
        projectTitle: 'Propose designing, construction and completion of “Sekolah Rendah Kebangsaan Tembangau 6” Bera, Pahang Darul Makmur',
        client: 'Ministry Of Education Malaysia',
        mainContract: '',
        period: '22.01.2002',
        commencement: '23.10.2000',
        value: 'RM8,500,000.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Pavement Rehabilitation for Jalan Felda Bukit Rokan Barat Section 14, 15, 16, 17, 18, 19, 20, In The District Of Jempol, Negeri Sembilan',
        client: 'Selia Selenggara Sdn. Bhd.',
        mainContract: '',
        period: '10.12.2001',
        commencement: '30.11.2001',
        value: 'RM448,862.40'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Pavement Rehabilitation for Jalan Felda Bukit Rokan Barat Section 0, 1, 2, 3, In District Of Jempol, Negeri Sembilan',
        client: 'Selia Selenggara Sdn. Bhd.',
        mainContract: '',
        period: '10.12.2001',
        commencement: '30.11.2001',
        value: 'RM514,000.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Propose construction Politeknik Muadzam Shah, Mukim Bebar, Daerah Pekan, Pahang. Earthwork Zone 3',
        client: 'Kausar Corporation Sdn. Bhd.',
        mainContract: '',
        period: '05.08.2001',
        commencement: '20.06.2001',
        value: 'RM92,235.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Propose construction of Politeknik Muadzam Shah, Mukim Bebar, Daerah Pekan, Pahang. Earthwork Zone 1 & 2',
        client: 'Kausar Corporation Sdn. Bhd.',
        mainContract: '',
        period: '22.07.2001',
        commencement: '23.05.2001',
        value: 'RM476,000.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of pending work for one (1) block of 4 level “makmal sains/kemahiran hidup” 1 level agricultural lab & other work related to “Sekolah Menengah Labis” Segamat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '31.12.2000',
        commencement: '18.08.2000',
        value: 'RM2,131,310.54'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of pending job at additional blocks & other work related to “Sekolah Menengah Ulu Tebrau” Johor Bahru, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '06.12.2000',
        commencement: '04.05.2000',
        value: 'RM2,236,400.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of “Bangunan & Kompleks Rumah Guru Di Sek. Keb. Seri Maju Jaya”  Machap, Simpang Renggam, Kluang, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '04.10.2000',
        commencement: '14.07.1999',
        value: 'RM4,038,054.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Construction & completion of additional one (1) block of 4 level building at SRJK © Paloh, Kluang, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '06.11.1999',
        commencement: '25.08.1998',
        value: 'RM2,065,072.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Propose construction & completion of additional block & other work related to “Sek. Men. Gelang Patah” Johor Bahru, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '27.10.1999',
        commencement: '03.09.1998',
        value: 'RM3,123,048.00'
      },
      {
        image: 'saj.png',
        projectTitle: 'Muar Water Supply Project – Stage 3 Contract No. 3(e) – New Works Phase 1 - construction & completion of 2.27ML elevated RC reservoir at Tanjung Agas, Muar, Johor',
        client: 'Syarikat Air Johor Sdn. Bhd.',
        mainContract: '',
        period: '10.06.1999',
        commencement: '15.05.1998',
        value: 'RM2,041,729.60'
      },
      {
        image: 'jkr-n9.png',
        projectTitle: 'Construction & completion of additional one (1) block of 3 level building and others work related to “Sekolah Menengah Batu Kikir” Jempol, Negeri Sembilan Darul Khusus',
        client: 'JKR Negeri Sembilan',
        mainContract: '',
        period: '09.08.1999',
        commencement: '26.01.1999',
        value: 'RM1,628,459.50'
      },
      {
        image: 'jkr-pahang.png',
        projectTitle: 'Construction & Completion of (3) blocks of “Sekolah Piawai” one (1) canteen, agricultural workshop, general work, & other work related to “Sekolah Menengah Chanis” Pahang Darul Makmur',
        client: 'JKR Negeri Pahang',
        mainContract: '',
        period: '28.12.1998',
        commencement: '30.06.1997',
        value: 'RM3,810,064.00'
      },
      {
        image: 'jland.png',
        projectTitle: 'Construction & completion of 572,000 Gallons Main Water Tank for propose of “Perumahan Anggota Pekerja Perbadanan Johor” at Partially Lot PTD 93732, Mukim Tebrau, Johor Bahru, Johor',
        client: 'Johor Land Berhad',
        mainContract: '',
        period: '02.12.1998',
        commencement: '',
        value: 'RM1,517,113.37'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Resurfacing “Jalan Persekutuan” for Batu Pahat, Muar & Segamat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '29.09.1998',
        commencement: '16.06.1997',
        value: 'RM3,140,134.00'
      },
      {
        image: 'llm.png',
        projectTitle: 'Maintenance works & minor repairs “Pejabat Wilayah Tengah” Kajang, Selangor',
        client: 'Lembaga Lebuhraya Malaysia',
        mainContract: '',
        period: '24.07.1996',
        commencement: '1995',
        value: 'RM600,000.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Propose construction of bridge at KM 182.0 “Laluan Satu” (1) Air Hitam – Segamat, Johor',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '31.01.1996',
        commencement: '25.11.1995',
        value: 'RM414,237.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Supply, construction and completion of structure Intake House Pump / Generator Sets, Gravity Filter Tanks & Service Tanks FRP (Fiber Reinforced Polyester & Electrical work as well as pipe connection of water supply system) at Ladang Felda Bera Selatan 01, Temerloh, Pahang',
        client: 'Felda Engineering Services Sdn. Bhd.',
        mainContract: '',
        period: '25.10.1994',
        commencement: '25.10.1994',
        value: 'RM911,715.00'
      },
      {
        image: 'placeholder.png',
        projectTitle: 'Supply & Lay Of 200MM Dia. Mild Steel Pipes From Booster Pump Sungai Galong To R. C. Reservoir At Felda Jengka 01, Jerantut, Pahang',
        client: 'JBA Pahang',
        mainContract: '',
        period: '31.12.1994',
        commencement: '31.12.1994',
        value: 'RM712,224.80'
      },
      {
        image: 'jkr.png',
        projectTitle: 'Design, supply, delivery, installation, commixing & construction of 20,000 IGPH treatment plant & associated works for “Bekalan Air Rancangan Pengumpulan Semula” Kedaik, Pahang',
        client: 'Unit JKR Dara',
        mainContract: '',
        period: '05.10.1994',
        commencement: '10.08.1992',
        value: 'RM11,317,100.00'
      },
      {
        image: 'pkenj.png',
        projectTitle: 'Contruction & Completion of 350,000 Gallon Elevated Water Tank & water supply related to “Kawasan Perindustrian Pagoh” Johor',
        client: 'Perbadanan Kemajuan Ekonomi Negeri Johor',
        mainContract: '',
        period: '31.01.1994',
        commencement: '08.02.1993',
        value: 'RM1,271, 064.00'
      },
      {
        image: 'jkr.png',
        projectTitle: 'Construction and completion supply of pipe 1600MM Dia. “Injap Salur, Injap Keruk” Modification of “Alur Keluar” & others work done related to Rancangan Bekalan Air Pusat Pelancongan Tasik Chini, Pahang Tenggara, Pahang',
        client: 'JKR Dara',
        mainContract: '',
        period: '14.08.1993',
        commencement: '01.04.1993',
        value: 'RM346,099.00'
      },
      {
        image: 'jkr-johor.png',
        projectTitle: 'Supply, construction & completion of 3 blocks 4 door semi-detached  of staffs’ house at Ladang Inas, Kuala Pilah, Negeri Sembilan',
        client: 'JKR Negeri Johor',
        mainContract: '',
        period: '20.03.1993',
        commencement: '1992',
        value: 'RM240,000.00'
      },
    ]
  }

  ngOnInit(): void {
  }

}
