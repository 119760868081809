<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape3 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Services</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-area text-center pt-100 pb-70">
    <div class="container">
        <div class="scetion-title mb-50">
            <h2>Nuva Provides All Those Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-swimming"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Swimming & Diving</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-teacher"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Teaching Swimming & Diving</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-fins"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Swimming Equipments</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-swimming-1"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Monthly Swimming Event</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-oxigen"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Monthly Diving Event</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item box-shadow">
                    <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-oxigen"></i></a>
                    <a routerLink="/service-details" class="service-head"><h3>Scuba Diving Equipments</h3></a>
                    <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject.</p>
                </div>
            </div>
        </div>
    </div>
</section>