<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Career</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Career</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row" *ngIf="!newPosition">
            <div class="col-lg-12">
                <h2>No open position.</h2>
            </div>
        </div>

        <div class="row" *ngIf="newPosition">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-card">
                    <div class="price-head">
                        <h1>Engineer</h1>
                        <span>1 position</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                        </ul>
                        <a href="mailto:binaindahmakmur@gmail.com" class="price-btn">Apply Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-card">
                    <div class="price-head">
                        <h1>Engineer</h1>
                        <span>1 position</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                        </ul>
                        <a href="mailto:binaindahmakmur@gmail.com" class="price-btn">Apply Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="pricing-card">
                    <div class="price-head">
                        <h1>Engineer</h1>
                        <span>1 position</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                            <li><i class="far fa-check-circle"></i> Lorem ipsum, dolor sit amet consectetur adipisicing elit.</li>
                        </ul>
                        <a href="mailto:binaindahmakmur@gmail.com" class="price-btn">Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>