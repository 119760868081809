<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape3 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Organisation Team</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Organisation Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="scetion-title text-center mb-50">
            <h2>Organisation Team</h2>
        </div>

        <p-organizationChart [value]="data1" styleClass="company">
            <ng-template let-node pTemplate="bod">
                {{node.label}}
            </ng-template>
            <ng-template let-node pTemplate="person">
                <div class="node-header">{{node.label}}</div>
                <div class="node-content">
                    <div>{{node.data.name}}</div>
                </div>
            </ng-template>
            <ng-template let-node pTemplate="department">
                {{node.label}}
            </ng-template>
            <ng-template let-node pTemplate="project">
                <div class="node-header-project">{{node.label}}</div>
                <div class="node-content-project">
                    <div>{{node.data.name}}</div>
                </div>
            </ng-template>
        </p-organizationChart>


    </div>
</section>