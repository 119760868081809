<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Facilities Operation & Maintenance</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Facilities Operation & Maintenance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-detl pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-img">
                    <img src="assets/img/home-1/image-7-770.jpeg" alt="Images">
                </div>

                <div class="common-dtel-text">
                    <h2>Facilities Operation & Maintenance</h2>
                    <p>Daily upkeep and maintenance. Daily visual inspection. Daily visual and pre-detection work of the system. Scheduled maintenance work in accordance to CMIS. Repair work. Monthly report and proposal(s) to customers on equipment performance. To co-ordinate “fire drill” exercise with client and the fire department. Supervise all maintenance work carried out by licensed vendor(s).</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                   
                   <div class="widget-categories">
                       <h2>Our Scopes</h2>

                       <ul>
                           <li><span>Air Conditioning System </span></li>
                           <li><span>Fire Prevention System </span></li>
                           <li><span>Electrical & Lighting System </span></li>
                           <li><span>Lifts, Escalators & Walkalators</span></li>
                       </ul>
                   </div>

                   <div class="common-offer">
                    <div class="drive-img">
                        <img src="assets/img/home-1/image-1.jpeg" alt="Images">
                    </div>
                    
                    <div class="common-offer-text">
                        <h3>Need Our Help?</h3>
                        <a href="tel:+079444222" class="call-btn">Call Now</a>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>