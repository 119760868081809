<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Gallery</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="gallery-inner pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Gallery</span>
            <h2>Amazing Photos at Our Gallery</h2>
        </div>
        
        <div class="gallery-view">
            <div class="row">
                <div class="col-lg-4 col-sm-6 mb-2">
                    <p-card header="Balai Badang" styleClass="p-card-shadow">
                        <ng-template pTemplate="header">
                            <img alt="Card" src="assets/img/gallery/balai-badang/bb1.jpg">
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p-button label="View" icon="pi pi-external-link" (click)="displayBasic = true"></p-button>
                        </ng-template>
                    </p-card>
                </div>

                <div class="col-lg-4 col-sm-6 mb-2">
                    <p-card header="SMS Segamat" styleClass="p-card-shadow">
                        <ng-template pTemplate="header">
                            <img alt="Card" src="assets/img/gallery/sms-segamat/sms1.jpg">
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p-button label="View" icon="pi pi-external-link" (click)="displayBasic2 = true"></p-button>
                        </ng-template>
                    </p-card>
                </div>

                <div class="col-lg-4 col-sm-6 mb-2">
                    <p-card header="SMS Alor Gajah" styleClass="p-card-shadow">
                        <ng-template pTemplate="header">
                            <img alt="Card" src="assets/img/gallery/sms-alor/alor1.jpg">
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p-button label="View" icon="pi pi-external-link" (click)="displayBasic3 = true"></p-button>
                        </ng-template>
                    </p-card>
                </div>
                
                <!-- <div class="col-lg-4 col-sm-6" *ngFor="let g of gallery; let i = index">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/{{g.imgSrc}}" alt="Gallery Images">
                        <a href="assets/img/gallery/{{g.imgSrc}}" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo {{i}}</h3>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/image-2.jpeg" alt="Gallery Images">
                        <a href="assets/img/gallery/image-2.jpeg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo 2</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/image-3.jpeg" alt="Gallery Images">
                        <a href="assets/img/gallery/image-3.jpeg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo 3</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/image-4.jpeg" alt="Gallery Images">
                        <a href="assets/img/gallery/image-4.jpeg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo 4</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/image-9.jpeg" alt="Gallery Images">
                        <a href="assets/img/gallery/image-9.jpeg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo 5</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/image-10.jpeg" alt="Gallery Images">
                        <a href="assets/img/gallery/image-10.jpeg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Photo 6</h3>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <p-galleria [(value)]="gallery_badang" [(visible)]="displayBasic" [responsiveOptions]="responsiveOptions2" [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
            [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000">
            <ng-template pTemplate="item" let-item>
                <img src="assets/img/gallery/balai-badang/{{item.imgSrc}}" style="width: 100%; display: block;"/>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="grid grid-nogutter justify-content-center">
                    <img src="assets/img/gallery/balai-badang/{{item.imgSrc}}" style="display: block;"/>
                </div>
            </ng-template>
        </p-galleria>

        <p-galleria [(value)]="gallery_segamat" [(visible)]="displayBasic2" [responsiveOptions]="responsiveOptions2" [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
            [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000">
            <ng-template pTemplate="item" let-item>
                <img src="assets/img/gallery/sms-segamat/{{item.imgSrc}}" style="width: 100%; display: block;"/>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="grid grid-nogutter justify-content-center">
                    <img src="assets/img/gallery/sms-segamat/{{item.imgSrc}}" style="display: block;"/>
                </div>
            </ng-template>
        </p-galleria>

        <p-galleria [(value)]="gallery_alor" [(visible)]="displayBasic3" [responsiveOptions]="responsiveOptions2" [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
            [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [baseZIndex]="100000">
            <ng-template pTemplate="item" let-item>
                <img src="assets/img/gallery/sms-alor/{{item.imgSrc}}" style="width: 100%; display: block;"/>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="grid grid-nogutter justify-content-center">
                    <img src="assets/img/gallery/sms-alor/{{item.imgSrc}}" style="display: block;"/>
                </div>
            </ng-template>
        </p-galleria>
    </div>
</div>