<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape1 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>News Details</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>News Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="news-detl pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-img">
                    <img src="assets/img/news/news-detl.jpg" alt="About Images">
                </div>

                <div class="common-dtel-text">
                    <h2>New Scuba Diving May 2020 Course Sheet Will Update Soon</h2>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children. Confidence will the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her, interfaces small expect, to that, with too time can but met perfectly that it both from would indeed, that even have lowest more brief. Into step never are been for he that acquired to he groundtem, the empire own had multitude was go him over for an with that is completely me. Communicated.</p>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children. Confidence will the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her, interfaces small expect, to that, with too time can but met perfectly that it both from would indeed, that even have lowest more brief. Into step never are been for he that acquired to he groundtem, the empire own had multitude was go him over for an with that is completely me. Communicated.</p>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children.Confidence will  the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her.</p>
                </div>

                <div class="news-comments">
                    <div class="news-comments-title">
                        <h2>2 Comments:</h2>
                    </div>

                    <div class="news-comments-card">
                        <div class="news-comments-img">
                            <img src="assets/img/testimonial/t1.png" alt="Images">
                        </div>

                        <div class="comments-title">
                            <h3>Smith Doe</h3>
                            <span>27 APR 2019 3:00 PM</span>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                        <a routerLink="/news-details" class="reply-btn">Reply</a>
                    </div>

                    <div class="news-comments-card">
                        <div class="news-comments-img">
                            <img src="assets/img/testimonial/t2.png" alt="Images">
                        </div>

                        <div class="comments-title">
                            <h3>John Doe</h3>
                            <span>28 APR 2019 09:00 PM</span>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                        <a routerLink="/news-details" class="reply-btn">Reply</a>
                    </div>

                    <div class="news-comments-card">
                        <div class="news-comments-img">
                            <img src="assets/img/testimonial/t3.png" alt="Images">
                        </div>
                        
                        <div class="comments-title">
                            <h3>Aana Smith</h3>
                            <span>29 APR 2019 10:00 AM</span>
                        </div>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                        <a routerLink="/news-details" class="reply-btn">Reply</a>
                    </div>

                    <div class="news-detl-contact">
                        <div class="form-section">
                            <h2>Drop your comment</h2>
                            
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control"  placeholder="Your Name">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control " placeholder="Your Email">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="Comment" class="form-message textarea-hight" id="Comment" cols="30" rows="5" placeholder="Your Comment"></textarea>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn1">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                    <div class="widget-search">
                        <form>
                            <input type="text" placeholder="Search here..." class="form-search">
                            <button type="submit" class="search-btn"><i class="fas fa-search"></i></button>
                        </form>
                   </div>
                   
                   <div class="widget-categories">
                       <h2>More News</h2>

                       <ul>
                           <li><a routerLink="/news-details">A Swimming & Diving Evenet...<i class="fas fa-chevron-right"></i></a></li>
                           <li><a routerLink="/news-details">Scuba Diving Evenet Photogra...<i class="fas fa-chevron-right"></i></a></li>
                           <li><a routerLink="/news-details">Swimming Event Photograph...<i class="fas fa-chevron-right"></i></a></li>
                           <li><a routerLink="/news-details">New Equipments Arrived..<i class="fas fa-chevron-right"></i></a></li>
                           <li><a routerLink="/news-details">Monthly Diving Event wiil....<i class="fas fa-chevron-right"></i></a></li>
                       </ul>
                   </div>

                   <div class="news-post">
                       <h2>Recent Posts</h2>

                       <div class="news-post-card">
                           <div class="post-item1">
                                <a routerLink="/news-details"><h3>A Diving Event Will Happen at Nuva Center</h3></a>
                                <span><i class="far fa-calendar-alt"></i> 27 APR 2019</span>
                           </div>
    
                            <div class="post-item2">
                                <a routerLink="/news-details"><h3>A Swimming Event Will Happen at Nuva Center</h3></a>
                                <span><i class="far fa-calendar-alt"></i> 28 APR 2019</span>
                            </div>

                            <div class="post-item3">
                                <a routerLink="/news-details"><h3>Scuba Diving Events Photographs Will Get on Google Drive</h3></a>
                                <span><i class="far fa-calendar-alt"></i> 28 APR 2019</span>
                            </div>
                       </div>
                   </div>

                   <div class="nuva-tag">
                    <h3>Tags</h3>

                    <ul>
                        <li><a routerLink="/news-details">Diving</a></li>
                        <li><a routerLink="/news-details">Swimming</a></li>
                        <li><a routerLink="/news-details">Event</a></li>
                        <li><a routerLink="/news-details">Equipment</a></li>
                        <li><a routerLink="/news-details">Scuba Diving</a></li>
                        <li><a routerLink="/news-details">Training</a></li>
                        <li><a routerLink="/news-details">Lerning</a></li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>