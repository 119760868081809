import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public userForm!: FormGroup;
  name = '';
  email = '';
  phone_number = '';
  msg_subject = '';
  message = '';

  constructor(private mailService: EmailService, private router: Router) { 
    this.formControl();
  }

  ngOnInit(): void {
  }

  submitEmail() {
    if (this.userForm.valid){
      this.mailService.sendMail(this.email, "BIM Website: " + this.msg_subject, this.name,  " Name: " + this.name.toUpperCase() +  " <br> Email:" + this.email + " <br> Phone number:" + this.phone_number + " <br> Message:" + this.message)
      
      this.formControl();

      this.router.navigate(['/email-sent']);
    }
    
  }

  formControl() {
    this.userForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      phone_number: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      msg_subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    })
  }

}
