import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  about1 = ""
  about2 = ""

  constructor() { 
    this.about1 = "Binaindah Makmur (M) Sdn Bhd was incorporated 1st September 1994 to fulfill the growing needs for caliber Bumiputera Contractor " + 
    "to serve the country's rapid economic growth. Binaindah Makmur (M) Sdn Bhd has since grown and expanded its services in total facility management, " +
    "operations and maintainance, mechanical and electrical services, building automation system, energy management and event management operations and maintainance, " +
    "mechanical and electrical services, building automation system, energy management and event management. "+
    "Binaindah Makmur (M) Sdn Bhd is now a G7 class Bumiputera contractor.";
    
    this.about2 = "In our efforts to provide total solutions to our customers delight, Binaindah Makmur (M) Sdn Bhd’s large pool of capable resources which includes "+ 
    "in-house specialist, strategic partners and consultants, purchasing team, suppliers and sub-contractors have been constantly assisting our customers in sourcing " +
    "for the best and most suitable materials and supplies in terms of quality, reliability and competitive pricing. "
  }

  ngOnInit(): void {
  }

}
