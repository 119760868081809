import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router, private titleService: Title,  
        private metaTagService: Meta) {
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.titleService.setTitle("Top Construction Development Company, Malaysia - Binaindah Makmur (M)"); 

        this.metaTagService.addTags([  
            { name: 'keywords', content: 'construction, construction company, best building construction, air conditioning system, property construction, facilites management, supply construction material, Johor, Malaysia, JKR, Kementerian' },  
            { name: 'robots', content: 'index, follow' },  
            { name: 'writer', content: 'Richtech Solutions' },  
            { charset: 'UTF-8' } ,
            { name: 'description', content: 'Binaindah Makmur (M) Sdn Bhd has since grown and expanded its services in total facility management, operations and maintainance, mechanical and electrical services, building automation system, energy management and event management operations and maintainance, mechanical and electrical services, building automation system, energy management and event management. Binaindah Makmur (M) Sdn Bhd is now a G7 class Bumiputera contractor.'},
            { name: 'allow-search', content: 'yes' }
          ]);  
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}