<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Integrated Facilities Management</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Integrated Facilities Management</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-detl pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-img">
                    <img src="assets/img/home-1/image-11-770.jpeg" alt="Images">
                </div>

                <div class="common-dtel-text">
                    <p>Facility Management Services provided by Binaindah Makmur (M) Sdn Bhd is divided into 3 categories as follows:-</p>
                </div>

                <div class="common-dtel-text">
                    <h2>Scheduled Maintenance</h2>
                    <p>We carry out timely inspection of facilities on a regular basis and follow best maintenance practices to ensure optimum cost, effectiveness and efficiency.</p>
                </div>

                <div class="common-dtel-text">
                    <h2>AD HOC Maintenance</h2>
                    <p>We provide round the clock coverage making use of our expertise and latest technology to ensure total customer satisfaction.</p>
                </div>

                <div class="common-dtel-text">
                    <h2>Upgrading & Renovation</h2>
                    <p>We advise our customer in Design, Build and perform all upgrading works within the premises.</p>
                </div>

                
                <div class="scetion-title mt-5">
                    <h2>Scope of Services</h2>
                </div>
                <div class="p-2 box-shadow">
                    <span routerLink="/service-details" class="service-head"><h3>Property Management</h3></span>
                    <p>Interior & exterior building cleaning. Hard & soft landscaping. Security & monitoring. Pest & hygiene control. Garbage disposal services. Swimming pool maintenance.</p>
                </div>
                <p></p>
                <div class="p-2 box-shadow">
                    <span routerLink="/service-details" class="service-head"><h3>Mechanical & Electrical</h3></span>
                    <p>Air-conditioning system. Fire prevention system. Electrical & lighting system. Lifts, escalators & walkalators.</p>
                </div>
                <p></p>
                <div class="p-2 box-shadow">
                    <span routerLink="/service-details" class="service-head"><h3>Civil Structural</h3></span>
                    <p>Civil engineering works. Building works. Mechanical sanitary & water engineering works. Jungle clearing & land preparation. Specialist civil engineering works.</p>
                </div>

                <div class="scetion-title mt-5">
                    <h2>Types of Maintenance</h2>
                </div>

                <div class="faq-area">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <span class="accordion-title active"> Proactive Maintenance</span>
                            <p class="accordion-content show">Involves usage of specialised tools and equipment to identify, repair and solve equipment problems at an early stage.</p>
                        </li>
        
                        <li class="accordion-item">
                            <span class="accordion-title active"> Preventive Maintenance</span>
                            <p class="accordion-content show">Involves maintenance works performed on a planned schedule which includes inspection, servicing and cleaning.</p>
                        </li>
        
                        <li class="accordion-item">
                            <span class="accordion-title active"> Predictive Maintenance</span>
                            <p class="accordion-content show">Involves using high end monitoring tools to detect any malfunctions early before any breakdown can occur.</p>
                        </li>

                        <li class="accordion-item">
                            <span class="accordion-title active"> Corrective Maintenance</span>
                            <p class="accordion-content show">Involves immediate repair works due to equipment or machinery failure.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                    <div class="common-offer">
                        <div class="drive-img">
                            <img src="assets/img/home-1/image-1.jpeg" alt="Images">
                        </div>
                        
                        <div class="common-offer-text">
                            <h3>Need Our Help?</h3>
                            <a href="tel:+079444222" class="call-btn">Call Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>