<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Courses</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Courses</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Courses</span>
            <h2>Our All Courses</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/3.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$49</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>10 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Scuba Diving</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/1.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$59</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>20 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Swimming Training</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/2.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$54</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>11 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Free Diving</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/4.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$32</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>10 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Deep-sea Diving</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/5.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$55</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>13 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Swim Underwater</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a routerLink="/"><img src="assets/img/course/6.jpg" alt="Course Images"></a>
                        <div class="courses-card-text">$45</div>
                    </div>
                    <ul>
                        <li class="active">Available Now</li>
                        <li>13 Days Long</li>
                    </ul>
                    <div class="courses-card-content">
                        <a routerLink="/"><h3>Snorkeling Diving</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/" class="course-book-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>