<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav nav-top">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Home <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One </a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Book Appointment</a></li>

                                <li class="nav-item"><a routerLink="/instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor</a></li>

                                <li class="nav-item"><a routerLink="/courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                                
                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Page</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Services <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services </a></li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">News <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a></li>

                                <li class="nav-item"><a routerLink="/news-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Shop <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a></li>
                                
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>

                    <div class="other-side">
                        <div class="search-area">
                            <div class="other-option">
                                <div class="search-item">
                                    <a href="#" class="search-btn"><i class="flaticon-loupe"></i></a>
                                    <i class="close-btn fas fa-times"></i>

                                    <div class="search-overlay search-popup">
                                        <div class="search-box">
                                            <form class="search-form">
                                                <input class="search-input" name="search" placeholder="Search" type="text">
                                                <button class="search-button" type="submit"><i class="flaticon-loupe"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="search-text">Search</div>

                        <div class="cart-area">
                            <a class="cart-icon" routerLink="/cart">
                                <i class="flaticon-shopping-cart"></i>
                                <p>Cart <span>(2)</span></p>
                            </a>
                        </div>

                        <div class="modal-menu">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#myModal2">
                                <i class="flaticon-menu"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div class="sidebar-modal">  
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times"></i></span></button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                    </h2>
                </div>
                
                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">About Us</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, asperiores doloribus eum laboriosam praesentium delectus unde magni aut perspiciatis cumque deserunt dolore voluptate, autem pariatur.</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li><a routerLink="/log-in">Log In</a></li>
                            <li><a routerLink="/sign-up">Sign Up</a></li>
                            <li><a routerLink="/faq">FAQ</a></li>
                            <li><a routerLink="/">Logout</a></li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li><i class="fas fa-map-marker-alt"></i> Address <span>28/A street, New York, USA</span></li>
                            <li><i class="far fa-envelope"></i> Email <span>email@nuva.com</span></li>
                            <li><i class="fas fa-mobile-alt"></i> Phone <span>+1 123 456 789, +1 123 454 699</span></li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class='fab fa-facebook-f'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-google-plus-g'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>