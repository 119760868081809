// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig : {
    apiKey: "AIzaSyDe0dcDE2ZtTZk-Lb3CxOwlSF4oM3iSTaM",
    authDomain: "binaindah-web.firebaseapp.com",
    projectId: "binaindah-web",
    storageBucket: "binaindah-web.appspot.com",
    messagingSenderId: "725776225025",
    appId: "1:725776225025:web:b685d6333306aec2c3c100",
    measurementId: "G-GWY6M68G8C"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
