<header class="header-area top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="header-left">
                    <ul>
                        <li>
                            <i class="fas fa-clock"></i>
                            MON - FRI (8:00 AM - 5:30PM)
                        </li>
                        <!-- <li>
                            <i class="fas fa-map-marker-alt"></i>
                            NO 15, JALAN PUTERI, TAMAN SEGAMAT JAYA, BULOH KASAP, 85010 SEGAMAT, JOHOR
                        </li> -->
                        <li>
                            <a href="tel:+079444222">
                                <i class="fas fa-phone-alt"></i>
                                +07-9444222
                            </a>
                        </li>
                        <li>
                            <a href="tel:+079443222">
                                <i class="fas fa-fax"></i>
                                +07-9443222
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-4">
                <div class="social-icon">
                    <ul>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>  
                            <a href="#" target="_blank">
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">The Company <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/corporate-structure" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corporate Structure</a></li>

                                <li class="nav-item"><a routerLink="/org-chart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Organisation Team</a></li>

                                <li class="nav-item"><a routerLink="/achievements" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Awards & Achievements</a></li>

                                <li class="nav-item"><a routerLink="/career" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Career</a></li>

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Services <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/facilities-operation-maintenance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Facilities Operation & Maintenance </a></li>

                                <li class="nav-item"><a routerLink="/supply-construction-material" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Supply Construction Material</a></li>

                                <li class="nav-item"><a routerLink="/construction-civil-engineering" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Construction, Civil & Engineering</a></li>

                                <li class="nav-item"><a routerLink="/integrated-facilities-management" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Integrated Facilities Management</a></li>

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Projects <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/current-project" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Current Projects</a></li>

                                <li class="nav-item"><a routerLink="/completed-project" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Completed Projects</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>


                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>

                    <!-- <div class="other-side">
                        <div class="search-area">
                            <div class="other-option">
                                <div class="search-item">
                                    <a href="#" class="search-btn"><i class="flaticon-loupe"></i></a>

                                    <i class="close-btn fas fa-times"></i>

                                    <div class="search-overlay search-popup">
                                        <div class="search-box">
                                            <form class="search-form">
                                                <input class="search-input" name="search" placeholder="Search" type="text">
                                                <button class="search-button" type="submit"><i class="flaticon-loupe"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="search-text">
                            Search
                        </div>
                        
                        <div class="cart-area">
                            <a class="cart-icon" routerLink="/cart"><i class="flaticon-shopping-cart"></i> <p>Cart <span>(2)</span></p></a>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>