<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="contact-title text-center mb-50">
            <h2>Our Business Hours is Available From 8:00 AM to 5:30 PM (Monday - Friday) and Contact is Available 24/7</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="far fa-clock"></i></div>
                    <h3>Mon - Fri : 8:00 AM - 5:30 PM</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="fas fa-map-marker-alt"></i></div>
                    <h3>
                        <strong>Binaindah Makmur (M) Sdn Bhd</strong>
                        No.15, Jalan Puteri, 
                        Taman Segamat Jaya, Buloh Kasap, 
                        85010 Segamat, Johor
                    </h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-item text-center">
                    <div class="icon-contact"><i class="flaticon-telephone"></i></div>
                    <h3><a href="tel:+079444222">+07-9444222</a></h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="contact-img">
                    <img src="assets/img/contact-us.jpeg" alt="Contact Images">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-area">
                    <div class="form-section">
                        <h2>Let Us Know What's On Your Mind?</h2>

                        <form id="contactForm" [formGroup]="userForm" (ngSubmit)="submitEmail()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" formControlName="name" [(ngModel)]="name" class="form-control" required placeholder="Your Name">
                                    </div>
                                    <div *ngIf="userForm.controls['name'].invalid && (userForm.controls['name'].dirty || userForm.controls['name'].touched)"
                                        class="alert alert-danger">

                                        <div *ngIf="userForm.controls['name'].errors?.['required']">
                                            Name is required.
                                        </div>

                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" formControlName="email" [(ngModel)]="email" class="form-control " required placeholder="Your Email">
                                    </div>
                                    <div *ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)"
                                        class="alert alert-danger">

                                        <div *ngIf="userForm.controls['email'].errors?.['required']">
                                            Email is required.
                                        </div>

                                        <div *ngIf="userForm.controls['email'].errors?.['email']">
                                            Email is invalid.
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" [(ngModel)]="phone_number" formControlName="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                    <div *ngIf="userForm.controls['phone_number'].invalid && (userForm.controls['phone_number'].dirty || userForm.controls['phone_number'].touched)"
                                        class="alert alert-danger">

                                        <div *ngIf="userForm.controls['phone_number'].errors?.['required']">
                                            Phone number is required.
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" [(ngModel)]="msg_subject" formControlName="msg_subject" class="form-control" required placeholder="Subject">
                                    </div>
                                    <div *ngIf="userForm.controls['msg_subject'].invalid && (userForm.controls['msg_subject'].dirty || userForm.controls['msg_subject'].touched)"
                                        class="alert alert-danger">

                                        <div *ngIf="userForm.controls['msg_subject'].errors?.['required']">
                                            Subject is required.
                                        </div>

                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" [(ngModel)]="message" class="form-message textarea-hight" formControlName="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                    </div>
                                    <div *ngIf="userForm.controls['message'].invalid && (userForm.controls['message'].dirty || userForm.controls['message'].touched)"
                                        class="alert alert-danger">

                                        <div *ngIf="userForm.controls['message'].errors?.['required']">
                                            Subject is required.
                                        </div>

                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn1 btn-two" [disabled]="!userForm.valid">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>