<footer class="footer-area">
    <div class="footer-top pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="footer-content">
                        <div class="footer-conten-title">
                            <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a> 
                            <p>BINAINDAH MAKMUR (M) SDN. BHD. <br>
                            Company No: 314039-X</p>
                        </div>
                        
                        <!-- <div class="newsleter-area">
                            <h3>Join Newsletter</h3>
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Email" name="EMAIL" required autocomplete="off">
                                <button class="subscribe-btn" type="submit">Subscribe</button>
                            </form>
                        </div> -->

                        <div class="footer-social">
                            <ul>
                                <!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> -->
                                <!-- <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-pinterest"></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6">
                    <div class="footer-list">
                        <h3>Our Services</h3>

                        <ul>
                            <li><a routerLink="/facilities-operation-maintenance">Facilities Operation & Maintenance</a></li>
                            <li><a routerLink="/supply-construction-material">Supply Construction Material</a></li>
                            <li><a routerLink="/construction-civil-engineering">Construction, Civil & Engineering</a></li>
                            <li><a routerLink="/integrated-facilities-management">Integrated Facilities Management</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="footer-list ml-50">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/corporate-structure">Corporate Structure</a></li>
                            <li><a routerLink="/career">Careers</a></li>
                            <li><a routerLink="/gallery">Gallery</a></li>
                            <li><a routerLink="/current-project">Current Project</a></li>
                            <li><a routerLink="/completed-project">Completed Project</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="footer-list">
                        <h3>Contacts</h3>

                        <ul>
                            <li><a href="tel:+6079444222"><i class="fas fa-mobile-alt"></i> +607-9444222</a></li>
                            <li><a href="tel:+6079443222"><i class="fas fa-fax"></i> +607-9443222</a></li>
                            <!-- <li><a href="mailto:email@binaindahmakmur.com"><i class="far fa-envelope"></i> email@binaindahmakmur.com</a></li> -->
                            <li><a href="mailto:binaindahmakmur@gmail.com"><i class="far fa-envelope">

                            </i> binaindahmakmur@gmail.com</a></li>
                            <li><i class="fas fa-map-marker-alt"></i>
                                No.15, Jalan Puteri, 
                                Taman Segamat Jaya, Buloh Kasap, 
                                85010 Segamat, Johor
                                </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer-bottom">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="bottom-list">
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/facilities-operation-maintenance">Services</a></li>
                            <li><a routerLink="/current-project">Projects</a></li>
                            <li><a routerLink="/gallery">Gallery</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="bottom-text">
                        <p>©1994-{{year}} <strong>BIM</strong>. All Rights Reserved by <a href="/">BIM</a>. <br> Powered by <a target="_blank" href="http://www.richtechsolutions.com.my/">Richtech Solutions</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>