<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Construction, Civil & Engineering</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Construction, Civil & Engineering</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-detl pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-img">
                    <img src="assets/img/home-1/image-10-770.jpeg" alt="Images">
                </div>

                <div class="common-dtel-text">
                    <h2>Property Construction</h2>
                    <p>We have completed numerous infrastructure works such as earthworks, water supply (rural and urban) and roads (single carriageway and dual carriageway). Various types of building construction has enriched our experience be it low rise or high rise, conventional or Industrial Building System (IBS) complete with mechanical and electrical systems.</p>
                    <p>All these construction experience is done through various contractual arrangements; conventional, design and build or Public Finance Initiative (PFI). These experiences have equipped us to scale to greater heights.</p>
                </div>

                <div class="common-dtel-text">
                    <h2>Specialties in Civil Engineering Works</h2>
                    <ol>
                        <li>
                            <p>Piling Works</p>
                            <ul>
                                <li>
                                    <p>In site concrete</p>
                                </li>
                                <li>
                                    <p>Precast reinforced concrete</p>
                                </li>
                                <li>
                                    <p>Steel</p>
                                </li>
                                <li>
                                    <p>Timber</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Sub soil drainage</p>
                        </li>
                        <li>
                            <p>Water distribution network</p>
                        </li>
                        <li>
                            <p>Steel structural works</p>
                        </li>
                        <li>
                            <p>Bituminous road surface, road signs and markings</p>
                            <ul>
                                <li>
                                    <p>Guard rail barriers</p>
                                </li>
                                <li>
                                    <p>Milestones, etc</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Precast reinforced or non-reinforced concrete beams, kerbs, culverts & drains</p>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                   
                   <div class="widget-categories">
                       <h2>Civil Engineering Work Scope</h2>

                       <ul>
                           <li><span>General Civil Engineering Work</span></li>
                           <li><span>Bridges, Jetties & Marine Structures</span></li>
                           <li><span>Water Retaining Structures</span></li>
                           <li><span>Sewerage Systems</span></li>
                           <li><span>Flood Mitigation Systems</span></li>
                           <li><span>Irrigation & Drainage Systems</span></li>
                           <li><span>General Civil Engineering Work</span></li>
                           <li><span>Joint Boxes and Duct Construction</span></li>
                           <li><span>Manholes for Cable Networks</span></li>
                       </ul>
                   </div>

                    <div class="common-offer">
                        <div class="drive-img">
                            <img src="assets/img/home-1/image-1.jpeg" alt="Images">
                        </div>
                        
                        <div class="common-offer-text">
                            <h3>Need Our Help?</h3>
                            <a href="tel:+079444222" class="call-btn">Call Now</a>
                        </div>
                    </div>

                    <div class="widget-categories">
                        <h2>Construction Work Scope</h2>
    
                        <ul>
                            <li><span>Jungle Clearing & Land Preparation Works</span></li>
                            <li><span>Mechanical Sanitation & Water Engineering Works</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-lg-8">
                <div class="widget-categories">
                    <h2>Construction Work Scope</h2>

                    <ul>
                        <li><span>Jungle Clearing & Land Preparation Works</span></li>
                        <li><span>Mechanical Sanitation & Water Engineering Works</span></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>