import { Component, OnInit } from '@angular/core';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent implements OnInit {
  data1: TreeNode[];

  constructor() { 

    this.data1 = [{
      label: 'BOARD OF DIRECTOR',
      type: 'bod',
      styleClass: 'p-bod',
      expanded: true,
      data: {name:'', 'avatar': 'walter.jpg'},
      children: [
        {
          label: 'DIRECTOR / CEO',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name:'SURESH KUMAR A/L BALAKRISHAN', 'avatar': 'saul.jpg'},
          children:[{
            label: 'GENERAL MANAGER',
            type: 'person',
            styleClass: 'p-person',
            expanded: true,
            data: {name:'AHMAD BAKRI BIN ABDUL RAHIM ', 'avatar': 'mike.jpg'},
            children:[{
              label: 'HQ TEAM',
              styleClass: 'department-hq',
              expanded: true,
              children:[{
                label: 'ACCOUNT & FINANCE',
                styleClass: 'department-hq',
                expanded: true,
                children:[
                  {
                    label: 'MAYA ISHARAYA ISMAIL',
                    styleClass: 'department-hq-1',
                    expanded: true,
                    children:[{
                      label: 'NORHAYATI ABDULLAH',
                      styleClass: 'department-hq-1',
                    }]
                  },
                ]
              },
              {
                  label: 'PROCUREMENT',
                  styleClass: 'department-hq',
                  expanded: true,
                  children:[
                    {
                      label: 'AZMIL AMRIE MOHAMED',
                      styleClass: 'department-hq-1',
                      expanded: true,
                      children:[{
                        label: 'NORA NUHU',
                        styleClass: 'department-hq-1',
                      }]
                    },
                  ]
              },
              {
                  label: 'SIRIM AND TENDERING',
                  styleClass: 'department-hq',
                  expanded: true,
                  children:[
                    {
                      label: 'SRI AYU SURIANI KAMIL',
                      styleClass: 'department-hq-1',
                    },
                  ]
              },
              {
                label: 'HUMAN RESOURCES',
                styleClass: 'department-hq',
                expanded: true,
                children:[
                  {
                    label: 'NUR SYUHADAH AB RAHMAN',
                    styleClass: 'department-hq-1',
                  },
                ]
              }
            ],
            },

            {
                label: 'PROJECT TEAM',
                styleClass: 'department-project',
                expanded: true,
                children:[
                  {
                    label: 'PROJECT MANAGER',
                    type: 'project',
                    styleClass: 'p-project',
                    expanded: true,
                    data: {name:'SALIMAN BIN SALAMON'},
                    children: [
                      {
                        label: 'JUNIOR QS',
                        type: 'project',
                        styleClass: 'p-project',
                        expanded: true,
                        data: {name:'NUR EFFA ZIRA'},
                        children: [
                          {
                            label: 'QA / QC',
                            type: 'project',
                            styleClass: 'p-project',
                            expanded: true,
                            data: {name:'AAINAA WAJIHAH'},
                            children: [
                              {
                                label: 'ENGINEER',
                                type: 'project',
                                styleClass: 'p-project',
                                expanded: true,
                                data: {name:'ZULIKHA AMRAN'},
                                children: [
                                  {
                                    label: 'SURVEYOR',
                                    type: 'project',
                                    styleClass: 'p-project',
                                    expanded: true,
                                    data: {name:'KHAIRUDIN NAI-RAT'}
                                  },
                                ]
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
            }],
          }],
        },
      ]
    }];
  }

  ngOnInit(): void {
  }

}
