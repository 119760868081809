import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  gallery = [];
  gallery_badang = [];
  gallery_segamat = [];
  gallery_alor = [];

  displayBasic: boolean;
  displayBasic2: boolean;
  displayBasic3: boolean;

  responsiveOptions2:any[] = [
    {
        breakpoint: '1500px',
        numVisible: 5
    },
    {
        breakpoint: '1024px',
        numVisible: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

  constructor() { }

  ngOnInit(): void {
    for (let i =0; i<24; i++) {
      this.gallery_badang.push({
        imgSrc: 'bb' + (i+1) + ".jpg"
      })
    }

    for (let i=0; i<13; i++) {
      this.gallery_segamat.push({
        imgSrc: 'sms' + (i+1) + ".jpg"
      })
    }

    for (let i=0; i<26; i++) {
      this.gallery_alor.push({
        imgSrc: 'alor' + (i+1) + ".jpg"
      })
    }

    // this.gallery = [
    //   {
    //     imgSrc: 'image-1.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-2.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-3.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-4.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-5.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-6.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-7.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-8.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-9.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-10.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-11.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-12.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-13.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-14.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-15.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-16.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-17.jpeg',
    //   },
    //   {
    //     imgSrc: 'image-18.jpeg',
    //   },
    // ]

    
  }

}
