<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>News</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="news-area pt-94 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-30">
            <h2>Our Regular Latest News & Updates</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/1.jpg" alt="News Images"></a>
                        <div class="sub-text">
                            <a routerLink="/news">SCUBA DIVING</a>
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a> </li>
                        <li>26 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>New Scuba Diving Course Sheet Will Update Soon</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/2.jpg" alt="News Images"></a>
                        <div class="sub-text">
                            <a routerLink="/news">
                                SWIMMING EVENT
                            </a> 
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a></li>
                        <li>27 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details">
                            <h3>A Diving Event Will Happen at Nuva Center</h3>
                        </a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/3.jpg" alt="News Images"></a>
                        <div class="sub-text">
                            <a routerLink="/news">EQUIPMENT</a>
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a></li>
                        <li>28 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>A Swimming Event Will Happen at Nuva Center</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details">
                            <img src="assets/img/news/4.jpg" alt="News Images">
                        </a>
                        <div class="sub-text">
                            <a routerLink="/news">SCUBA DIVING</a>
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a></li>
                        <li>29 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>Scuba Diving Events Photographs Will Get on Google Drive</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details">
                            <img src="assets/img/news/5.jpg" alt="News Images">
                        </a>
                        <div class="sub-text"> 
                            <a routerLink="/news">SWIMMING EVENT</a> 
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a></li>
                        <li>30 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>A Swimming Event Photographs Will Get on Google Drive</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/6.jpg" alt="News Images"></a>
                        <div class="sub-text">
                            <a routerLink="/news">EQUIPMENT</a> 
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/news">John Doe</a> </li>
                        <li>01 MAY 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>Equipments Arrived. Equipments Will Find on Our Store</h3></a>
                        <p>Less entirely counter. Parent, curiously,must would frequency, would very of wellfeigned legs,doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/news" class="prev page-numbers"><i class="fas fa-chevron-left"></i></a>
                    <a routerLink="/news" class="page-numbers current" aria-current="page">1</a>
                    <a routerLink="/news" class="page-numbers ">2</a>
                    <a routerLink="/news" class="page-numbers">3</a>
                    <a routerLink="/news" class="next page-numbers"><i class="fas fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>