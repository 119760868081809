<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape1 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Achievements</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Achievements</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonials-inner pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <h2>Awards & Achievements</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="single-testominal">
                    <!-- <img src="assets/img/testimonial/t1.png" alt="Images">
                    <h3>Smith Doe</h3>
                    <span>Software Engineer</span> -->
                    <p>Three (3) Star Rating standard by SME CORP & CIDB for good management and technical capabilities, compliance to best practices and good project management.</p>
                    <!-- <ul>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                    </ul> -->
                 </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testominal">
                    <p>Excellent Contractor Award 2007 (Build and finish up route from Kampung Kuala Paya to Kampung Balai Badang, Segamat, Johor by JKR.</p>
                 </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testominal">
                    <p>Recognized as the Quality Management System for the Provision of Construction Services for Building and Civil Engineering Work by SIRIM QAS International Sdn Bhd.</p>
                 </div>
            </div>
        </div>
    </div>
</div>