<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>404 Error</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>404 Error</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <h1>4 <span>0</span> 4</h1>
                <h3>Oops! Page Not Found</h3>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <a routerLink="/" class="default-btn1 page-btn">Return To Home Page</a>
            </div>
        </div>
    </div>
</div>