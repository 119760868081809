<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Supply Construction Material</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Supply Construction Material</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-detl pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-img">
                    <img src="assets/img/home-1/image-9.jpeg" alt="Images">
                </div>

                <div class="common-dtel-text">
                    <h2>Supply Construction Material</h2>
                    <p>Through our group of companies, Binaindah Makmur (M) Sdn Bhd also action as a construction material supplier covering Southern area of Peninsular Malaysia.</p>
                    <p>Our supply of construction material in Malaysia range from aggregates, cement, quarry material such as bitumen, crusher run and sand for road construction.</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                    

                    <div class="common-offer">
                        <div class="drive-img">
                            <img src="assets/img/home-1/image-1.jpeg" alt="Images">
                        </div>
                        
                        <div class="common-offer-text">
                            <h3>Need Our Help?</h3>
                            <a href="tel:+079444222" class="call-btn">Call Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>