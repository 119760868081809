import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-details-four',
  templateUrl: './services-details-four.component.html',
  styleUrls: ['./services-details-four.component.scss']
})
export class ServicesDetailsFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
