<app-navbar-style-one></app-navbar-style-one>

<div class="home-slider">
    <div class="slider-area owl-carousel owl-theme">
        <div class="slider-item overlay-image slider-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-text">
                            <h1>THE BEST BUILDING CONSTRUCTION</h1>
                            <p></p>
                            <div class="slider-btn">
                                <a routerLink="/about" class="default-btn1">Explore More</a>
                                <a routerLink="/contact" class="default-btn2 ml-20">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-text">
                            <h1>ORIENTED</h1>
                            <p></p>
                            <div class="slider-btn">
                                <a routerLink="/contact" class="default-btn1">Contact Us</a>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-text">
                            <h1>INNOVATIVE</h1>
                            <p></p>
                            <div class="slider-btn">
                                <a routerLink="/contact" class="default-btn1">Contact Us</a>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-text">
                            <h1>ACHIEVER</h1>
                            <p></p>
                            <div class="slider-btn">
                                <a routerLink="/contact" class="default-btn1">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-area pt-100">
    <div class="container">
        
        <div class="u-margin-bottom-big text-center">
            <h2 class="heading-secondary">
                CLIENTELE
            </h2>
       </div>
        <div class="brand-slider owl-carousel owl-theme">
            <div class="brand-item" *ngFor="let i of imgSource">
                <a href=""><img [src]="i.imgSrc" alt="Client Images"></a>
            </div>

            <!-- 
            <div class="brand-item">
                <a routerLink="/cart"><img src="assets/img/brand/2.png" alt="Brand Images"></a>
            </div>

            <div class="brand-item">
                <a routerLink="/cart"><img src="assets/img/brand/3.png" alt="Brand Images"></a>
            </div>

            <div class="brand-item">
                <a routerLink="/cart"><img src="assets/img/brand/4.png" alt="Brand Images"></a>
            </div>

            <div class="brand-item">
                <a routerLink="/cart"><img src="assets/img/brand/5.png" alt="Brand Images"></a>
            </div> -->
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="scetion-title">
                        <span>About Us</span>
                        <h2>Binaindah Makmur (M) Sdn. Bhd. formerly known as Bina Makmur Keratong (M) Sdn. Bhd.</h2>
                        <p>Established on the 1st September 1994 as a Private Limited Company with the aim of under taking jobs / works of Building and Civil Engineering Construction as its original and primary objective.</p>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/about" class="default-btn2">Know More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-right">
                    <div class="play-area">
                        <img src="assets/img/contact-us.jpeg" alt="About Us Images">
                        <!-- <a href="https://www.youtube.com/watch?v=sNKKlO17ynk" class="play-btn"><i class="flaticon-multimedia"></i> Play a Video</a> -->
                    </div>

                    <div class="icon-shape-1"><i class="flaticon-sea"></i></div>
                    <div class="icon-shape-2"><i class="flaticon-sea"></i></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class="service-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Services</span>
            <h2>Nuva Provides Services</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="service-item-area owl-carousel owl-theme">
            <div class="service-item">
                <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-swimming"></i></a>
                <a routerLink="/service-details" class="service-head"><h3>Swimming & Diving</h3></a>
                <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject</p>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-teacher"></i></a>
                <a routerLink="/service-details" class="service-head"><h3>Teaching Swimming & Diving</h3></a>
                <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject</p>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-fins"></i></a>
                <a routerLink="/service-details" class="service-head"><h3>Swimming Equipments</h3></a>
                <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject</p>
            </div>

            <div class="service-item">
                <a routerLink="/service-details" class="service-item-icon"><i class="flaticon-swimming"></i></a>
                <a routerLink="/service-details" class="service-head"><h3>Swimming & Diving</h3></a>
                <p>As sign what each derided to organizaasd tion. Remodelling that, win word as have the proceeded lots subject</p>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="apply-area">
    <div class="container">
        <div class="apply-text">
            <span>Apply for Job</span>
            <h2>We Are Hiring Expertise for Three Different Categories</h2>
            <p>It willingly room, drew even in place structure  he its holding to view. Need harder my in how there's into receive wanted ambushed that home, his this it position. Room, on by was it heard parks. Compared one Room. Testimony you mister music.Discipline assets briefs with tone completely</p>
            <div class="apply-btn">
                <a routerLink="/" class="default-btn1">Apply Now</a>
                <a routerLink="/" class="default-btn2 ml-20">Our Career</a>
            </div>
        </div>
    </div>
</div> -->
<!-- 
<div class="course-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="course-item">
                    <div class="course-text">
                        <span>Swimming Course</span>
                        <h2>Swimming Course Taken by Our Most Experienced Trainer</h2>
                        <p>Attempt. Origin it the get and the been came into the fitted would another behind think insur is ance sister hell mild, back least on war, can of, remote become into and and that the that as a  me skyline was first him many instruments, nearby a to he purpose series helplessly didn't has a long question tricoloured half here, the links needed in considerable.</p>
                        <p>Months known was first own, them. Travelling of by velocity agreed by clock of this to dedicat ed with days, and on viewings the brought we her with been decision-making.</p>
                        <div class="course-btn">
                            <a routerLink="/courses" class="default-btn2">Take Course</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="course-img">
                    <img src="assets/img/course/1.jpg" alt="Course Images">
                </div>

                <div class="course-dots">
                    <img src="assets/img/shape/dots-blue.png" alt="Dots Images">
                </div>
            </div>
        </div>

        <div class="course-another pt-70">
            <div class="row">
                <div class="col-lg-5">
                    <div class="course-img-2">
                        <img src="assets/img/course/2.jpg" alt="Course Images">
                    </div>

                    <div class="course-dots-2">
                        <img src="assets/img/shape/dots-pink.png" alt="Dots Images">
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="course-item-2">
                        <div class="course-text">
                            <span>Diving Course</span>
                            <h2>Diving Course Taken by Our Most  Experienced Trainer</h2>
                            <p>Attempt. Origin it the get and the been came into the fitted would another behind think insur is ance sister hell mild, back least on war, can of, remote become into and and that the that as a  me skyline was first him many instruments, nearby a to he purpose series helplessly didn't has a long question tricoloured half here, the links needed in considerable.</p>
                            <p>Months known was first own, them. Travelling of by velocity agreed by clock of this to dedicat ed with days, and on viewings the brought we her with been decision-making.</p>
                            <div class="course-btn">
                                <a routerLink="/courses" class="default-btn2">Take Course</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="icon-shape-3"><i class="flaticon-swimming"></i></div>
    <div class="icon-shape-4"><i class="flaticon-diver"></i></div>
</div>

<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Products </span>
            <h2>Buy the Equipment You Need</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/1.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Glass</h3></a>
                            <span>$35</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/2.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Rite</h3></a>
                            <span>$30</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/3.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Fins Bungee</h3></a>
                            <span>$75</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/4.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Life Jacket</h3></a>
                            <span>$25</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Gallery</span>
            <h2>Amazing Photos at Our Daily Nuva</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="gallery-view">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/1.jpg"><img src="assets/img/gallery/1.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>Baby Girl Learn Swimming</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/2.jpg"><img src="assets/img/gallery/2.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>John at His Swim Event</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/3.jpg"><img src="assets/img/gallery/3.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>Scuba Diving Instruction</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/4.jpg"><img src="assets/img/gallery/4.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>Under Water Scuba Diving</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/5.jpg"><img src="assets/img/gallery/5.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>Mike’s Frist Dive for Nuva</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="gallery-item">
                        <a href="assets/img/gallery/6.jpg"><img src="assets/img/gallery/6.jpg" alt="Gallery Images"></a>

                        <div class="gallery-text">
                            <h3>Boy Learning Swimming</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="trainers-area pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Trainers</span>
            <h2>Our Professional Trainers</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/1.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>John Doe</h3></a>
                        <span>Professional Diver</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/2.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Evana Doe</h3></a>
                        <span>Professional Trainer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/3.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content active">
                        <a routerLink="/instructor"><h3>Smith Doe</h3></a>
                        <span>Professional Swimmer</span>
                        <div class="social-icon active">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/4.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Marida Doe</h3></a>
                        <span>Professional Instructor</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="news-area pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our News</span>
            <h2>Daily Updates of Our Nuva</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/1.jpg" alt="News Images"></a>

                        <div class="sub-text">
                            <a routerLink="/">SCUBA DIVING</a> 
                        </div>
                    </div>
                    <ul>
                        <li><a href="">John Doe</a> </li>
                        <li>26 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>New Scuba Diving Course Sheet Will Update Soon</h3></a>
                        <p>Less entirely counter. Parent, curiously, must would frequency, would very of wellfeigned legs, doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/2.jpg" alt="News Images"></a>

                        <div class="sub-text"> 
                            <a routerLink="/">SWIMMING EVENT</a>
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/">John Doe</a> </li>
                        <li>27 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>A Diving Event Will Happen at Nuva Center</h3></a>
                        <p>Less entirely counter. Parent, curiously, must would frequency, would very of wellfeigned legs, doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 offset-lg-0 col-md-6 offset-md-3">
                <div class="news-card">
                    <div class="news-img">
                        <a routerLink="/news-details"><img src="assets/img/news/3.jpg" alt="News Images"></a>

                        <div class="sub-text">
                            <a routerLink="/">EQUIPMENT</a>
                        </div>
                    </div>
                    <ul>
                        <li><a routerLink="/">John Doe</a> </li>
                        <li>28 APR 2019</li>
                    </ul>
                    <div class="news-content">
                        <a routerLink="/news-details"><h3>A Swimming Event Will Happen at Nuva Center</h3></a>
                        <p>Less entirely counter. Parent, curiously, must would frequency, would very of wellfeigned legs, doctor back in the business.</p>
                        <a routerLink="/news-details" class="news-icon">Read More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->