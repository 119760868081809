import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  imgSource: Clients[] = [];

  constructor() { }

  ngOnInit(): void {
      this.imgSource = [{
          imgSrc: 'assets/img/clients/jata_kpm.png'
      },
      {
        imgSrc: 'assets/img/clients/jkr-johor.png'
      },
      {
        imgSrc: 'assets/img/clients/jkr-n9.png'
      },
      {
        imgSrc: 'assets/img/clients/jkr-pahang.png'
      },
      {
        imgSrc: 'assets/img/clients/jkr-segamat.png'
      },
      // {
      //   imgSrc: 'assets/img/clients/jkr.png'
      // },
      {
        imgSrc: 'assets/img/clients/jland.png'
      },
      {
        imgSrc: 'assets/img/clients/jps.png'
      },
      {
        imgSrc: 'assets/img/clients/kplb.png'
      },
      {
        imgSrc: 'assets/img/clients/kpm.png'
      },
      {
        imgSrc: 'assets/img/clients/llm.png'
      },
      {
        imgSrc: 'assets/img/clients/paip.png'
      },
      {
        imgSrc: 'assets/img/clients/pkenj.png'
      },
      {
        imgSrc: 'assets/img/clients/saj.png'
      },
    ]
  }



}

export interface Clients {
  imgSrc: string
}
